@import "https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap";
:root {
  --primary-color: #5100ff;
  --secondary-color: #56258f;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --white-color: #fff;
  --black-color: #000;
}

body {
  margin: 0;
  padding: 0;
  font-family: JetBrains Mono, monospace;
}

.grid-container {
  box-sizing: border-box;
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
  width: 100%;
  height: calc(100vh - 5rem);
  margin-top: 5rem;
  display: grid;
  position: relative;
}

.widget-container {
  box-sizing: border-box;
  z-index: 1;
  background-color: #fff;
  border: 2px solid #0000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  display: flex !important;
}

.widget-container[style*="span 1 / span 1"] {
  background-color: #007bff1a;
}

.widget-container[style*="span 2 / span 1"] {
  background-color: #007bff33;
}

.widget-container[style*="span 3 / span 2"] {
  background-color: #007bff4d;
}

.widget-container:hover {
  border-color: #007bff;
  box-shadow: 0 0 10px #007bff80;
}

.widget-actions {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.widget-container:hover .widget-actions {
  display: flex;
}

.widget-action-button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  transition: background-color .3s;
  display: flex;
}

.widget-action-button:hover {
  background-color: #0056b3;
}

.md-dialog {
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  min-width: 300px;
  max-width: 100%;
  max-height: 100%;
  padding: 24px;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
}

.md-dialog::backdrop {
  background-color: #00000080;
}

.md-dialog-title {
  color: #000000de;
  margin: 0 0 20px;
  font-size: 1.25rem;
  font-weight: 500;
}

.md-form-field-inline {
  width: calc(50% - 10px);
  margin-right: 20px;
  display: inline-block;
}

.md-form-field-inline:last-child {
  margin-right: 0;
}

.md-input, .md-select {
  background-color: #0000;
  border: 1px solid #0000001f;
  border-radius: 4px;
  max-width: calc(100% - 32px);
  padding: 12px 16px;
  font-size: 1rem;
  transition: border-color .2s;
}

.md-select {
  max-width: 100%;
}

.md-form-field label {
  color: #0009;
  pointer-events: none;
  transition: all .2s;
  position: absolute;
  top: 12px;
  left: 16px;
}

.md-input:focus + label, .md-input:not(:placeholder-shown) + label, .md-select:focus + label, .md-select:not(:invalid) + label {
  background-color: #fff;
  padding: 0 4px;
  font-size: .75rem;
  top: -6px;
  left: 12px;
}

.md-dialog-actions {
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

.md-button {
  text-transform: uppercase;
  cursor: pointer;
  color: #1976d2;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: .875rem;
  font-weight: 500;
  transition: background-color .2s;
}

.md-button:hover {
  background-color: #1976d20a;
}

.md-button-primary {
  color: #fff;
  background-color: #1976d2;
}

.md-button-primary:hover {
  background-color: #1565c0;
}

.md-button-warning {
  color: #f44336;
}

.md-button-warning:hover {
  background-color: #f443360a;
}

.md-settings-menu {
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  background-color: #fff;
  border-radius: 4px;
  width: 300px;
  max-width: calc(100% - 40px);
  padding: 24px;
  transition: opacity .5s, transform .3s;
  position: fixed;
  bottom: 80px;
  right: 20px;
  transform: translateY(20px);
  box-shadow: 0 2px 10px #0000001a;
}

.md-settings-menu.visible {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.md-settings-title {
  color: #000000de;
  margin: 0 0 24px;
  font-size: 20px;
  font-weight: 500;
}

.md-form-field {
  margin-bottom: 20px;
  position: relative;
}

.md-input, .md-select {
  background-color: #0000;
  border: 1px solid #0000001f;
  border-radius: 4px;
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  transition: border-color .2s;
}

.md-input:focus, .md-select:focus {
  border-color: #1976d2;
  outline: none;
}

.md-form-field label {
  color: #0009;
  pointer-events: none;
  font-size: 16px;
  transition: all .2s;
  position: absolute;
  top: 12px;
  left: 16px;
}

.md-input:focus + label, .md-input:not(:placeholder-shown) + label, .md-select:focus + label, .md-select:not(:invalid) + label {
  color: #1976d2;
  background-color: #fff;
  padding: 0 4px;
  font-size: 12px;
  top: -6px;
  left: 12px;
}

.md-switch {
  align-items: center;
  margin: 20px 0;
  display: flex;
}

.md-switch-input {
  appearance: none;
  cursor: pointer;
  background-color: #00000061;
  border-radius: 7px;
  width: 36px;
  height: 14px;
  margin-right: 8px;
  transition: background-color .2s;
  position: relative;
}

.md-switch-input:before {
  content: "";
  background-color: #fafafa;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: transform .2s;
  position: absolute;
  top: -3px;
  left: -1px;
  box-shadow: 0 2px 4px #0003;
}

.md-switch-input:checked {
  background-color: #1976d280;
}

.md-switch-input:checked:before {
  background-color: #1976d2;
  transform: translateX(18px);
}

.md-switch-label {
  color: #000000de;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
}

.md-form-actions {
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  pointer-events: none;
  opacity: 0;
  background-image: radial-gradient(circle, #000 10%, #0000 10.01%);
  background-position: 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  transition: transform .5s, opacity 1s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(10);
}

.ripple:active:after {
  opacity: .2;
  transition: all;
  transform: scale(0);
}

.grid-item {
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
}

.grid-item.grid-visible, body.show-grid .grid-item {
  background-color: #c8c8c81a;
  border: 1px dashed #ccc;
}

.grid-item.grid-visible:hover {
  background-color: #c8c8c84d;
}

#user-id-display {
  color: #fff;
  z-index: 1000;
  background-color: #00000080;
  border-radius: 8px;
  padding: 15px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  transition: all .3s ease-in-out;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

#user-id-display:hover {
  background-color: #000c;
  transform: translateX(40px)translateY(-30px)scale(1.25);
}

.fab {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 2px 5px #0003;
}

.widget-container {
  z-index: 3;
}

.dark-theme .grid-item {
  color: #fff;
  background-color: #333;
}

.dialog-bottom-left {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  box-shadow: 0 2px 10px #0000001a;
}

.md-fab {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 2px 5px #0003;
}

.md-fab:hover {
  background-color: var(--secondary-color);
}

.get-started-btn {
  text-align: center;
  text-transform: uppercase;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.get-started-btn:hover {
  background-color: var(--secondary-color);
}

.get-started-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px #1976d24d;
}

.get-started-btn:active {
  background-color: var(--secondary-color);
}

#clipboard-icon {
  opacity: 0;
  cursor: pointer;
  transition: all .3s ease-in-out;
  display: inline;
}

strong:hover {
  cursor: pointer;
}

strong:hover #clipboard-icon {
  opacity: 1;
  display: inline;
}
/*# sourceMappingURL=index.54650e58.css.map */
