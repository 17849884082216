@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

:root {
    --primary-color: #5100ff;
    --secondary-color: #56258f;
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #343a40;
    --white-color: #fff;
    --black-color: #000;
}

body {
    margin: 0;
    padding: 0;
    font-family: JetBrains Mono, monospace;
}

.grid-container {
    display: grid;
    width: 100%;
    height: calc(100vh - 5rem);
    box-sizing: border-box;
    position: relative;
    margin-top: 5rem;
    grid-auto-rows: minmax(100px, auto); /* Adjust the minimum row height as needed */
    gap: 10px; /* Adjust the gap between grid items as needed */
}

.widget-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 2px solid transparent;
    box-sizing: border-box;
    transition: all 0.3s ease;
    z-index: 1;
    overflow: hidden; /* Add this to prevent content from overflowing */
}

/* Add styles for different widget sizes */
.widget-container[style*="span 1 / span 1"] {
    /* Styles for 1x1 widgets */
    background-color:rgba(0, 123, 255, 0.1)
}

.widget-container[style*="span 2 / span 1"] {
    /* Styles for 2x2 widgets */
    background-color:rgba(0, 123, 255, 0.2)

}

.widget-container[style*="span 3 / span 2"] {
    /* Styles for 4x3 widgets */
    background-color:rgba(0, 123, 255, 0.3)

}

.widget-container:hover {
    border-color: #007bff;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.widget-actions {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: none;
}

.widget-container:hover .widget-actions {
    display: flex;
}

.widget-action-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.widget-action-button:hover {
    background-color: #0056b3;
}

/* Material Design-inspired styles */
.md-dialog {
  border: none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12);
  padding: 24px;
  min-width: 300px;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

.md-dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.md-dialog-title {
  margin: 0 0 20px 0;
  font-size: 1.25rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.md-form-field {
  position: relative;
  margin-bottom: 20px;
}

.md-form-field-inline {
  display: inline-block;
  width: calc(50% - 10px);
  margin-right: 20px;
}

.md-form-field-inline:last-child {
  margin-right: 0;
}

.md-input, 
.md-select {
  max-width: calc(100% - 32px);
  padding: 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 1rem;
  background-color: transparent;
  transition: border-color 0.2s;
}

.md-select {
  max-width: 100%;
}

.md-input:focus, .md-select:focus {
  outline: none;
  border-color: #1976d2;
}

.md-form-field label {
  position: absolute;
  left: 16px;
  top: 12px;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s;
  pointer-events: none;
}

.md-input:focus + label,
.md-input:not(:placeholder-shown) + label,
.md-select:focus + label,
.md-select:not(:invalid) + label {
  top: -6px;
  left: 12px;
  font-size: 0.75rem;
  padding: 0 4px;
  background-color: white;
}

.md-dialog-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.md-button {
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
  color: #1976d2;
  transition: background-color 0.2s;
}

.md-button:hover {
  background-color: rgba(25, 118, 210, 0.04);
}

.md-button-primary {
  background-color: #1976d2;
  color: white;
}

.md-button-primary:hover {
  background-color: #1565c0;
}

.md-button-warning {
  color: #f44336;
}

.md-button-warning:hover {
  background-color: rgba(244, 67, 54, 0.04);
}

.md-settings-menu {
  opacity: 0;
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 24px;
  width: 300px;
  max-width: calc(100% - 40px);
  z-index: 1000;
  transition: opacity 0.5s, transform 0.3s;
  transform: translateY(20px);
  pointer-events: none;
  /* TODO: display block none, or visibilty free up resources but I like the animation without */
}

.md-settings-menu.visible {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.md-settings-title {
  margin: 0 0 24px 0;
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.md-form-field {
  position: relative;
  margin-bottom: 20px;
}

.md-input,
.md-select {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 16px;
  background-color: transparent;
  transition: border-color 0.2s;
}

.md-input:focus,
.md-select:focus {
  outline: none;
  border-color: #1976d2;
}

.md-form-field label {
  position: absolute;
  left: 16px;
  top: 12px;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s;
  pointer-events: none;
  font-size: 16px;
}

.md-input:focus + label,
.md-input:not(:placeholder-shown) + label,
.md-select:focus + label,
.md-select:not(:invalid) + label {
  top: -6px;
  left: 12px;
  font-size: 12px;
  padding: 0 4px;
  background-color: #ffffff;
  color: #1976d2;
}

.md-switch {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.md-switch-input {
  appearance: none;
  width: 36px;
  height: 14px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.38);
  transition: background-color 0.2s;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
}

.md-switch-input::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.md-switch-input:checked {
  background-color: rgba(25, 118, 210, 0.5);
}

.md-switch-input:checked::before {
  transform: translateX(18px);
  background-color: #1976d2;
}

.md-switch-label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  user-select: none;
}

.md-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

/* Add this to your existing CSS for the ripple effect */
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: .2;
  transition: 0s;
}

.grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}

.grid-item.grid-visible {
    border: 1px dashed #ccc;
    background-color: rgba(200, 200, 200, 0.1);
}

body.show-grid .grid-item {
    border: 1px dashed #ccc;
    background-color: rgba(200, 200, 200, 0.1);
}

.grid-item.grid-visible:hover {
    background-color: rgba(200, 200, 200, 0.3);
}

/* body:not(.show-grid) .grid-item {
    border: none;
    background-color: transparent;
} */

#user-id-display {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 15px;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}

#user-id-display:hover {
  background-color: rgba(0, 0, 0, 0.8);
  transform: translateX(40px) translateY(-30px) scale(1.25);
}

.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 56px;
    height: 56px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000; /* Ensure it's above grid items */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.grid-container {
    /* z-index: 1; Ensure it's below the settings menu and FAB */
}

.grid-item {
    /* z-index: 2; Ensure it's below the settings menu and FAB, but above the grid container */
}

.widget-container {
    z-index: 3; /* Ensure widgets are above grid items */
}

.dark-theme .grid-item {
    background-color: #333;
    color: white;
}

.dialog-bottom-left {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.md-fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 56px;
    height: 56px;
    background-color:  var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.md-fab:hover {
    background-color:  var(--secondary-color);
}

.get-started-btn {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  background-color:  var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started-btn:hover {
  background-color:  var(--secondary-color);;
}

.get-started-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.3);
}

.get-started-btn:active {
  background-color:  var(--secondary-color);
}

/* Hide the clipboard icon by default */
#clipboard-icon {
    display: inline;
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

strong:hover {
    cursor: pointer;
}

strong:hover #clipboard-icon {
    display: inline;
    opacity: 1;
}